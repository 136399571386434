<template>
    <div>
        <section
            id="balance"
            style="padding: 0px 20px; background: #36d5f2"
            class="relative"
        >
            <div class="balance_text text-center">
                <div class="balance_text__title">
                    <span
                        v-html="
                            convertDate(transaction.created_at, 'full-date')
                        "
                    ></span>
                </div>
                <div class="balance_text__amount">
                    <div class="text-3xl font-bold block">
                        &pound;{{ transaction.sum }}.00
                    </div>
                </div>
            </div>
        </section>
        <div class="text-center mb-12">
            <div class="font-bold">Transaction ID</div>
            <div class="font-medium">{{ transaction.hashedId }}</div>
        </div>
        <div class="text-left text-sm">
            <div class="font-bold">
                Time of transaction:
                <span class="font-medium">{{ convertDateToTime(transaction.created_at) }}</span>
            </div>
            <div class="font-bold">
                Balance after transaction:
                <span class="font-medium">&pound;{{
                    transaction.balanceAfterTransaction
                }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["transaction"],
    methods: {
        convertDate(date, type) {
            var monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            var date = new Date(date);
            var day = date.getDate();
            var monthIndex = date.getMonth();
            var year = date.getFullYear();
            return type === "full-date"
                ? `${day} ${monthNames[monthIndex]} ${year}`
                : `${monthNames[monthIndex]} ${year}`;
        },
        convertDateToTime(date) {
            var date = new Date(date);
            var hour = date.getHours();
            var minutes = date.getMinutes();
            return `${hour}:${minutes}`;
        },
    },
    mounted() {
        console.log(this.transaction);
    },
};
</script>
<style lang="scss" scoped>
#balance {
    background: var(--main);
    background: rgb(54, 213, 242);
    background: linear-gradient(
        180deg,
        rgba(54, 213, 242, 1) 0%,
        rgba(0, 204, 255, 1) 100%
    );
    width: 100%;
    height: 110px;
    border-radius: 28px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    * {
        transition: all 200ms ease-in-out;
    }

    .balance_text {
        color: white;

        &__title {
            font-size: 13px;
            font-weight: 700;
        }

        &__amount {
            font-size: 20px;
            font-weight: bold;
            font-weight: 700;
            letter-spacing: 0.02rem;
        }
    }

    .balance_button {
        width: 60px;
        height: 60px;
        border: none;
        border-radius: 50%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;

        &:active {
            opacity: 0.8;
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }
}
</style>
