<template>
  <div>
    <!-- <AppLoader v-if="$store.state.globaLoading" :alternate="true" /> -->
    <AppModulrBalance
      style="margin-top: 20px"
      @openSheetTransactions="openSheetTransactions"
      @openModulrSheet="openPaymentSheet"
      :showTransactionsButton="true"
    />
    <!-- @openModulrSheet="openPaymentSheet" -->
    <!-- <a href="/guardian/missions">
      <p class="pending-missions" v-if="filteredInProgressActivities.length>0">
        {{ filteredInProgressActivities.length }} in progress missions
      </p></a
    > -->
    <!-- <RouterLink  to="/guardian/missions">
      <p class="pending-missions" v-if="pendingRewardMissionsGetter.length > 0">
        {{ pendingRewardMissionsGetter.length }} in pending missions
      </p></RouterLink
    > -->
    <RouterLink to="/guardian/missions" class="wrap">
        <button
        v-if="pendingRewardMissionsGetter.length > 0"
        style="width: 100%;"
          class="pending-missions"
        >
        {{ pendingRewardMissionsGetter.length }} in pending missions
        </button>
      </RouterLink>
    <p
      v-if="
        Object.keys(getSetupChildrens).length == 0 ||
        miniMissionsLengthGetter == 0 ||
        guardianBalance == 0
      "
      class="py-6 px-2 next"
    >
      What to do next
    </p>
    <div
      class="steps"
      :class="{
        no_step:
          Object.keys(getSetupChildrens).length > 0 &&
          miniMissionsLengthGetter > 0 &&
          guardianBalance > 0,
      }"
    >
      <RouterLink
          to="/guardian/children" class="wrap">
        <button
          v-if="Object.keys(getSetupChildrens).length == 0"
          class="button"
        >
          <!-- :class="{ 'step-completed': Object.keys(getSetupChildrens).length > 0}" -->
          Add a child!
          <svg
            v-if="Object.keys(getSetupChildrens).length > 0"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="inline-block"
          >
            <path
              fill="#56b897"
              d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z"
            />
          </svg>
        </button>
      </RouterLink>
      <RouterLink to="/guardian/missions" class="wrap">
        <button
          v-if="
            Object.keys(getSetupChildrens).length > 0 &&
            miniMissionsLengthGetter == 0
          "
          class="button"
        >
          <!-- :class="{ 'step-completed': miniMissionsLengthGetter > 0 }" -->
          Create a mission!
          <svg
            v-if="miniMissionsLengthGetter > 0"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="inline-block"
          >
            <path
              fill="#56b897"
              d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z"
            />
          </svg>
        </button>
      </RouterLink>
      <div class="wrap">
        <button
          @click="openPaymentSheet"
          v-if="
            Object.keys(getSetupChildrens).length > 0 &&
            miniMissionsLengthGetter > 0 &&
            guardianBalance == 0
          "
          class="button"
          :class="{ 'remove-ring': isClassAdded }"
        >
          <!-- :class="{ 'step-completed': guardianBalance > 0 }" -->
          Top up! <br />Get £10 free*
          <svg
            v-if="guardianBalance > 0"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="inline-block"
          >
            <path
              fill="#56b897"
              d="m10 16.4l-4-4L7.4 11l2.6 2.6L16.6 7L18 8.4l-8 8Z"
            />
          </svg>
        </button>
      </div>
    </div>
    <section id="recent">
      <h1>Today's activity</h1>
      <section
        v-if="
          pendingRewardMissionsGetter.length > 0 ||
          filteredInProgressActivities.length > 0 ||
          filteredCompletedActivities.length > 0 ||
          filteredFailedActivities.length > 0 ||
          filteredExpiredActivities.length > 0
        "
      >
        <div
          v-if="pendingRewardMissionsGetter.length > 0"
          class="recent_container"
        >
          <div
            v-for="activity in pendingRewardMissionsGetter"
            :key="activity.id"
            class="activity_item border border-[#e3e3e3]"
            style="display: block; height: auto"
            :class="{ expandedMission: expandedId == activity.id }"
            @click="expandPendingRewardMission(activity.id)"
          >
            <div>
              <div class="activity_item__container">
                <div class="activity_item__icon">
                  <img
                    :src="resolveImage(activity.child.avatarLink)"
                    alt="Activity Image"
                  />
                  <div class="activity_item__icon__name">
                    {{ activity.child.first_name }}
                  </div>
                </div>
                <div>
                  <div
                    class="activity_item__title"
                    style="word-wrap: break-word"
                  >
                    {{ activity.name }}
                  </div>

                  <div class="activity_item__description">
                    {{ convertTime(activity.date_times_mission[0].start_time) }}
                    to
                    {{ convertTime(activity.date_times_mission[0].end_time) }}
                  </div>
                </div>
              </div>
              <div
                v-if="activity.reward_type == 'money'"
                class="activity_item__amount flex items-center"
              >
                <span class="text-[#56B897] text-[0.70rem] mr-[2px] font-bold"
                  >+</span
                >
                <img
                  src="../../assets/images/reward_money.svg"
                  class="w-[16px] h-[16px] mt-[-2px]"
                  alt="Money"
                />
              </div>
              <div
                v-if="activity.reward_type == 'points'"
                class="activity_item__amount flex items-center"
              >
                <span class="text-[#58C1E1] text-[0.70rem] mr-[2px] font-bold"
                  >+</span
                >
                <img
                  src="../../assets/images/reward_points.svg"
                  class="w-[13px] h-[13px] mt-[-2px]"
                  alt="Points"
                />
              </div>
              <div
                v-if="activity.reward_type == 'wishlist'"
                class="activity_item__amount flex items-center"
              >
                <span class="text-[#EC6C6B] text-[0.70rem] mr-[2px] font-bold"
                  >+</span
                >
                <img
                  src="../../assets/images/reward_wishlist.svg"
                  class="w-[13px] h-[13px]"
                  alt="Wishlist"
                />
              </div>

              <div
                style="display: flex; position: absolute; bottom: 0; right: 0"
              >
                <span
                  class="text-[9px] bg-[#F8B465] text-white rounded-tl-[5px] py-[1px] px-[10px] font-semibold flex items-center justify-center"
                  >Pending Reward</span
                >
              </div>

              <div
                v-if="activity.reward_type == 'money'"
                class="text-center font-bold my-5 text-xl text-[#52cbf1]"
              >
                <div>
                  + &pound;{{ formatPrice(activity.sum_money.toString()) }}
                </div>
                <div class="text-[0.60rem] text-[#806157] font-light mt-[-5px]">
                  Was this mission successful?
                </div>
              </div>
              <div
                v-if="activity.reward_type == 'points'"
                class="text-center font-bold my-5 text-xl text-[#52cbf1]"
              >
                <div>
                  +
                  {{ activity.sum_points.toString() }} Points
                </div>
                <div class="text-[0.60rem] text-[#806157] font-light mt-[-5px]">
                  Was this mission successful?
                </div>
              </div>
              <div
                v-if="activity.reward_type == 'wishlist'"
                class="text-center font-bold my-5 text-xl text-[#52cbf1]"
              >
                <div>Product Reward</div>
                <div class="text-[0.60rem] text-[#806157] font-light mt-[-5px]">
                  Was this mission successful?
                </div>
              </div>

              <div v-if="Object.keys(activity).length > 0">
                <div
                  v-if="activity.date_times_mission.length > 1"
                  style="
                    text-align: center;
                    margin-bottom: 10px;
                    font-weight: 600;
                    font-size: 12px;
                  "
                >
                  Mission Stages
                </div>
                <div
                  v-if="activity.date_times_mission.length > 1"
                  class="extra"
                  style="margin-bottom: 15px"
                >
                  <div
                    v-for="(stage, index) in activity.date_times_mission"
                    :key="index"
                  >
                    <div
                      v-if="stage.status == 'completed'"
                      class="bullet bl-green click"
                      tooltip="Stage Completed"
                      flow="up"
                    ></div>
                    <div
                      v-else-if="
                        stage.status == 'failed' || stage.status == 'expired'
                      "
                      class="bullet bl-red"
                      tooltip="Stage Failed"
                      flow="up"
                    ></div>
                    <div
                      v-else-if="stage.status == 'pending_reward'"
                      class="bullet bl-orange"
                      tooltip="Pending Reward"
                      flow="up"
                    ></div>
                    <div
                      v-else-if="
                        stage.status == 'in_progress' &&
                        stage.start_date == currentDate
                      "
                      class="bullet"
                      tooltip="Stage In Progress"
                      flow="up"
                    >
                      <img
                        src="https://img.icons8.com/dusk/72/clock.png"
                        width="29"
                        height="29"
                        alt="clock"
                      />
                    </div>
                    <div
                      v-else
                      class="bullet"
                      tooltip="Future Stage"
                      flow="up"
                    ></div>
                    <div
                      v-if="stage.status == 'pending_reward'"
                      class="reactivateMission"
                      style="bottom: -50px"
                    >
                      <span
                        style="
                          cursor: pointer;
                          background: rgb(82, 80, 84);
                          border-bottom-left-radius: 30px;
                        "
                        >Confirm</span
                      >
                      <span
                        @click="$emit('dismiss')"
                        style="
                          cursor: pointer;
                          background: rgb(177, 174, 174);
                          border-bottom-right-radius: 30px;
                        "
                        >Reject</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="Object.keys(activity).length > 0"
                class="w-[90%] mx-auto"
              >
                <div
                  v-if="activity.date_times_mission.length > 1"
                  style="
                    font-weight: 400;
                    font-size: 13px;
                    text-align: center;
                    margin: 10px 0 0 0;
                  "
                >
                  Stages completed
                  <span style="font-weight: bold"
                    >{{ stagesCompleted(activity.date_times_mission) }}
                    out of
                    {{ activity.date_times_mission.length }}
                  </span>
                  <div
                    v-if="
                      stagesCompleted(activity.date_times_mission) > 0 &&
                      activity.reward_type == 'money'
                    "
                    style="font-size: 11px; margin-top: 5px"
                  >
                    Partial reward amount:
                    <span style="font-weight: bold"
                      >&pound;{{
                        formatPrice(
                          partialReward(
                            activity.sum_money,
                            rewardPercent(
                              stagesCompleted(activity.date_times_mission),
                              activity.date_times_mission.length
                            )
                          )
                        )
                      }}</span
                    >
                  </div>
                </div>

                <div
                  v-if="
                    activity.date_times_mission.length > 1 &&
                    activity.reward_type == 'money'
                  "
                  style="
                    margin: 20px auto 20px auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <button
                    v-if="stagesCompleted(activity.date_times_mission) > 0"
                    class="bg-[#56B897] p-2 rounded-xl font-semibold shadow-lg text-white text-xs w-full ml-2"
                    style="font-family: var(--sans) !important"
                    @click="giveReward(activity.id, activity.sum_money)"
                  >
                    Give full reward
                  </button>
                  <button
                    v-else
                    class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                    style="font-family: var(--sans) !important"
                    @click="giveReward(activity.id, activity.sum_money)"
                  >
                    Approve
                  </button>
                  <button
                    v-if="stagesCompleted(activity.date_times_mission) > 0"
                    class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                    @click="givePartialReward(activity.id, activity.sum_money)"
                  >
                    Partial reward
                  </button>
                  <button
                    v-else
                    class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                    style="font-family: var(--sans) !important"
                    @click="givePartialReward(activity.id, activity.sum_money)"
                  >
                    Not this time
                  </button>
                </div>

                <div
                  v-else
                  class="mx-auto mt-4 mb-8 flex justify-between items-center w-full"
                >
                  <button
                    v-if="activity.reward_type == 'wishlist'"
                    class="bg-[#56B897] p-2 rounded-[100px] font-semibold text-white text-xs w-full mr-2"
                    style="font-family: var(--sans) !important"
                    @click="
                      selectPendingAddress(activity.id, activity.product.price)
                    "
                  >
                    Approve
                  </button>
                  <button
                    v-else
                    class="bg-[#56B897] p-2 rounded-[100px] font-semibold text-white text-xs w-full mr-2"
                    style="font-family: var(--sans) !important"
                    @click="giveReward(activity.id, activity.sum_money)"
                  >
                    Approve
                  </button>
                  <button
                    class="bg-[#FF8F8F] p-2 rounded-[100px] font-semibold shadow-lg text-white text-xs w-full ml-2"
                    style="font-family: var(--sans) !important"
                    @click="givePartialReward(activity.id, activity.sum_money)"
                  >
                    Deny
                  </button>
                </div>
              </div>

              <div
                style="
                  margin: 0 auto;
                  display: none;
                  justify-content: center;
                  align-items: center;
                "
              >
                <button
                  @click="deleteMission(activity.id)"
                  style="
                    background: var(--main);
                    border: none;
                    padding: 8px 15px;
                    color: white;
                    border-radius: 5px;
                    margin-right: 10px;
                    width: 100%;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                    width="20"
                    height="20"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
                <button
                  @click="openMissionDetails(activity)"
                  style="
                    background: var(--main);
                    border: none;
                    padding: 8px 15px;
                    color: white;
                    border-radius: 5px;
                    width: 100%;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                    width="20"
                    height="20"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          :class="{
            recent_container: pendingRewardMissionsGetter.length <= 0,
          }"
        >
          <AppDashboardActivities
            @showDetails="openMissionDetails"
            :activities="filteredInProgressActivities"
          />
          <AppDashboardActivities
            @showDetails="openMissionDetails"
            :activities="filteredCompletedActivities"
          />
          <AppDashboardActivities
            @showDetails="openMissionDetails"
            :activities="filteredFailedActivities"
          />
          <AppDashboardActivities
            @showDetails="openMissionDetails"
            :activities="filteredExpiredActivities"
          />
        </div>
      </section>
      <div v-else class="recent_container">
        <div
          class="activity_item shadow"
          style="justify-content: center; align-items: center"
        >
          <div
            style="
              text-align: center;
              color: var(--secondary);
              font-size: 12px;
              font-weight: 500;
            "
          >
            You have no activity for today,<br />
            set a mission now!
          </div>
        </div>
      </div>
    </section>

    <Sheet
      :show.sync="missionDetailsSheet"
      :on-close="closeSheetMissionDetails"
    >
      <div>
        <div v-if="sheetMissionDetails.child">
          <img
            :src="resolveImage(sheetMissionDetails.child.avatarLink)"
            class="rounded-full object-cover w-[80px] h-[80px] mx-auto"
            alt="Avatar"
          />
          <div class="text-center font-bold mb-6">
            {{ sheetMissionDetails.child.first_name }}
          </div>
        </div>

        <div class="text-center text-xs text-[#9E9E9E]">Mission Title</div>
        <div
          class="text-sm text-center font-semibold w-[70%] mx-auto rounded break-words py-2 px-2 mt-1"
          style="box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px"
        >
          {{ sheetMissionDetails.name }}
        </div>
        <div class="text-center text-xs text-[#9E9E9E] mt-6">
          Mission Description
        </div>
        <div
          class="text-sm text-center font-semibold w-[70%] mx-auto rounded break-words py-2 px-2 mt-1"
          style="box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px"
        >
          {{ sheetMissionDetails.description }}
        </div>

        <div v-if="Object.keys(sheetMissionDetails).length > 0">
          <div class="text-center text-xs text-[#9E9E9E] mt-6">
            Mission Date
          </div>
          <div
            class="text-sm text-center font-semibold w-[70%] mx-auto rounded break-words py-2 px-2 mt-1"
            style="box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px"
          >
            {{
              monthToName(sheetMissionDetails.date_times_mission[0].start_date)
            }}
          </div>
          <div class="text-center text-xs text-[#9E9E9E] mt-6">
            Mission Time
          </div>
          <div
            class="text-sm text-center font-semibold flex mx-auto mt-2 w-[70%]"
          >
            <div
              class="text-sm rounded break-words py-2 px-4"
              style="box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px"
            >
              {{ missionStartHourSplit }}
            </div>
            <div class="mx-4 text-sm p-1 flex-1">to</div>
            <div
              class="text-sm rounded break-words py-2 px-4"
              style="box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px"
            >
              {{ missionEndHourSplit }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-center text-xs text-[#9E9E9E] mt-6">Reward</div>
          <div
            v-if="sheetMissionDetails.reward_type == 'money'"
            style="
              text-align: center;
              font-weight: 800;
              font-size: 25px;
              color: var(--main);
            "
          >
            + &pound;{{ formatPrice(sheetMissionDetails.sum_money.toString()) }}
          </div>
          <div
            v-if="sheetMissionDetails.reward_type == 'points'"
            style="
              text-align: center;
              font-weight: 800;
              font-size: 25px;
              color: var(--main);
            "
          >
            + {{ sheetMissionDetails.sum_points.toString() }} Points
          </div>
          <div
            v-if="sheetMissionDetails.reward_type == 'wishlist'"
            style="
              text-align: center;
              font-weight: 800;
              font-size: 25px;
              color: var(--main);
            "
          >
            Product Reward
          </div>

          <div class="text-center text-xs text-[#9E9E9E] mt-6">Status</div>
          <div class="text-center font-bold capitalize">
            {{
              sheetMissionDetails.date_times_mission
                ? sheetMissionDetails.date_times_mission[0].status
                : ""
            }}
          </div>

          <div v-if="Object.keys(sheetMissionDetails).length > 0">
            <div
              v-if="sheetMissionDetails.date_times_mission.length > 1"
              style="
                text-align: center;
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 12px;
              "
            >
              Mission Stages ({{
                sheetMissionDetails.date_times_mission.length
              }})
            </div>
            <div
              v-if="sheetMissionDetails.date_times_mission.length > 1"
              class="extra"
              style="
                margin: 0 auto 40px auto;
                flex-wrap: wrap;
                justify-content: center;
              "
            >
              <div
                v-for="(stage, index) in sheetMissionDetails.date_times_mission"
                :key="index"
                style="margin-bottom: 10px"
              >
                <div
                  v-if="stage.status == 'completed'"
                  class="bullet bl-green click"
                  tooltip="Stage Completed"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
                <div
                  v-else-if="
                    stage.status == 'failed' || stage.status == 'expired'
                  "
                  class="bullet bl-red"
                  tooltip="Stage Failed"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
                <div
                  v-else-if="stage.status == 'pending_reward'"
                  class="bullet bl-orange"
                  tooltip="Pending Reward"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
                <div
                  v-else-if="
                    stage.status == 'in_progress' &&
                    stage.start_date == currentDate
                  "
                  class="bullet"
                  tooltip="Stage In Progress"
                  flow="up"
                >
                  <img
                    src="https://img.icons8.com/dusk/72/clock.png"
                    width="29"
                    height="29"
                    alt="clock"
                  />
                </div>
                <div
                  v-else
                  class="bullet"
                  tooltip="Future Stage"
                  flow="up"
                  style="color: white; font-size: 11px; font-weight: 600"
                >
                  {{ index + 1 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      :show.sync="sheetMissionPendingRewardConfirm"
      :on-close="closeSheetPendingMissionConfirm"
    >
      <div style="font-weight: bold; text-align: center; word-wrap: break-word">
        {{ sheetMissionDetails.name }}
      </div>
      <div style="font-weight: 400; text-align: center; font-size: 14px">
        {{ sheetMissionDetails.description }}
      </div>
      <div
        v-if="sheetMissionDetails.reward_type == 'money'"
        style="
          text-align: center;
          font-weight: 800;
          margin: 40px 0;
          font-size: 25px;
          color: var(--main);
        "
      >
        + &pound;{{ formatPrice(sheetMissionDetails.sum_money.toString()) }}
      </div>
      <div
        v-if="sheetMissionDetails.reward_type == 'points'"
        style="
          text-align: center;
          font-weight: 800;
          margin: 40px 0;
          font-size: 25px;
          color: var(--main);
        "
      >
        + {{ sheetMissionDetails.sum_points.toString() }} Points
      </div>
      <div
        v-if="sheetMissionDetails.reward_type == 'wishlist'"
        style="
          text-align: center;
          font-weight: 800;
          margin: 40px 0;
          font-size: 25px;
          color: var(--main);
        "
      >
        Product Reward
      </div>
      <div v-if="Object.keys(sheetMissionDetails).length > 0">
        <div
          v-if="sheetMissionDetails.date_times_mission.length > 1"
          style="
            text-align: center;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 12px;
          "
        >
          Mission Stages
        </div>
        <div
          v-if="sheetMissionDetails.date_times_mission.length > 1"
          class="extra"
        >
          <div
            v-for="(stage, index) in sheetMissionDetails.date_times_mission"
            :key="index"
          >
            <div
              v-if="stage.status == 'completed'"
              class="bullet bl-green click"
              tooltip="Stage Completed"
              flow="up"
            ></div>
            <div
              v-else-if="stage.status == 'failed' || stage.status == 'expired'"
              class="bullet bl-red"
              tooltip="Stage Failed"
              flow="up"
            ></div>
            <div
              v-else-if="stage.status == 'pending_reward'"
              class="bullet bl-orange"
              tooltip="Pending Reward"
              flow="up"
            ></div>
            <div
              v-else-if="
                stage.status == 'in_progress' && stage.start_date == currentDate
              "
              class="bullet"
              tooltip="Stage In Progress"
              flow="up"
            >
              <img
                src="https://img.icons8.com/dusk/72/clock.png"
                width="29"
                height="29"
                alt="clock"
              />
            </div>
            <div v-else class="bullet" tooltip="Future Stage" flow="up"></div>
            <div
              v-if="stage.status == 'pending_reward'"
              class="reactivateMission"
              style="bottom: -50px"
            >
              <span
                style="
                  cursor: pointer;
                  background: rgb(82, 80, 84);
                  border-bottom-left-radius: 30px;
                "
                >Confirm</span
              >
              <span
                @click="$emit('dismiss')"
                style="
                  cursor: pointer;
                  background: rgb(177, 174, 174);
                  border-bottom-right-radius: 30px;
                "
                >Reject</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="Object.keys(sheetMissionDetails).length > 0">
        <div
          v-if="sheetMissionDetails.date_times_mission.length > 1"
          style="
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            margin: 10px 0 15px 0;
          "
        >
          Stages completed
          <span style="font-weight: bold"
            >{{ stagesCompleted(sheetMissionDetails.date_times_mission) }}
            out of
            {{ sheetMissionDetails.date_times_mission.length }}
          </span>
          <div
            v-if="
              stagesCompleted(sheetMissionDetails.date_times_mission) > 0 &&
              sheetMissionDetails.reward_type == 'money'
            "
            style="font-size: 13px; margin-top: 5px"
          >
            Partial reward amount:
            <span style="font-weight: bold"
              >&pound;{{
                formatPrice(
                  partialReward(
                    sheetMissionDetails.sum_money,
                    rewardPercent(
                      stagesCompleted(sheetMissionDetails.date_times_mission),
                      sheetMissionDetails.date_times_mission.length
                    )
                  )
                )
              }}</span
            >
          </div>
        </div>
        <div
          v-else
          style="font-family: 'Source Sans Pro', sans-serif;font-weight: 400;font-size: 16px;text-align: center;margin: 15px; 0 10px 0;"
        >
          Mission is
          <span
            v-if="
              sheetMissionDetails.date_times_mission[0].status ==
              'pending_reward'
            "
            style="font-weight: bold"
            >Pending Reward</span
          >
          <span v-else style="text-transform: capitalize; font-weight: bold">{{
            sheetMissionDetails.date_times_mission[0].status
          }}</span>
        </div>

        <div
          v-if="
            sheetMissionDetails.date_times_mission.length > 1 &&
            sheetMissionDetails.reward_type == 'money'
          "
          style="
            margin: 40px auto 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <button
            v-if="stagesCompleted(sheetMissionDetails.date_times_mission) > 0"
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              margin: 0 5px;
              width: 100%;
              font-weight: 600;
            "
            @click="
              giveReward(sheetMissionDetails.id, sheetMissionDetails.sum_money)
            "
          >
            Give full reward
          </button>
          <button
            v-else
            class="giveRewardButton"
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              width: 100%;
              margin: 0 5px;
              font-weight: 600;
            "
            @click="
              giveReward(sheetMissionDetails.id, sheetMissionDetails.sum_money)
            "
          >
            Give reward
          </button>
          <button
            v-if="stagesCompleted(sheetMissionDetails.date_times_mission) > 0"
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              margin: 0 5px;
              width: 100%;
              font-weight: 600;
            "
            @click="
              givePartialReward(
                sheetMissionDetails.id,
                sheetMissionDetails.sum_money
              )
            "
          >
            Partial reward
          </button>
          <button
            v-else
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              width: 100%;
              margin: 0 5px;
              font-weight: 600;
            "
            @click="
              givePartialReward(
                sheetMissionDetails.id,
                sheetMissionDetails.sum_money
              )
            "
          >
            Not this time
          </button>
        </div>

        <div
          v-else
          style="
            margin: 40px auto 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <button
            v-if="sheetMissionDetails.reward_type == 'wishlist'"
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              margin: 0 5px;
              width: 100%;
              font-weight: 600;
            "
            @click="
              selectPendingAddress(
                sheetMissionDetails.id,
                sheetMissionDetails.product.price
              )
            "
          >
            Give reward
          </button>
          <button
            v-else
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              width: 100%;
              margin: 0 5px;
              font-weight: 600;
            "
            @click="
              giveReward(sheetMissionDetails.id, sheetMissionDetails.sum_money)
            "
          >
            Give reward
          </button>
          <button
            style="
              background: var(--main);
              border: none;
              padding: 15px;
              color: white;
              border-radius: 50px;
              width: 100%;
              margin: 0 5px;
              font-weight: 600;
            "
            @click="
              givePartialReward(
                sheetMissionDetails.id,
                sheetMissionDetails.sum_money
              )
            "
          >
            Not this time
          </button>
        </div>
      </div>

      <div
        style="
          margin: 0 auto;
          display: none;
          justify-content: center;
          align-items: center;
        "
      >
        <button
          @click="deleteMission(sheetMissionDetails.id)"
          style="
            background: var(--main);
            border: none;
            padding: 8px 15px;
            color: white;
            border-radius: 5px;
            margin-right: 10px;
            width: 100%;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fff"
            width="20"
            height="20"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
        <button
          @click="openMissionDetails(sheetMissionDetails)"
          style="
            background: var(--main);
            border: none;
            padding: 8px 15px;
            color: white;
            border-radius: 5px;
            width: 100%;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fff"
            width="20"
            height="20"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </button>
      </div>
    </Sheet>

    <Sheet :on-close="closeSheetMissionEdit" :show.sync="sheetMissionEdit">
      <div slot="title">Mission Details</div>

      <div v-if="sheetMissionDetails.child">
        <img
          :src="resolveImage(sheetMissionDetails.child.avatarLink)"
          class="rounded-full object-cover w-[80px] h-[80px] mx-auto"
          alt="Avatar"
        />
        <div class="text-center font-bold mb-6">
          {{ sheetMissionDetails.child.first_name }}
        </div>
      </div>

      <div style="width: 100%">
        <div>
          <AppInput
            :dice="false"
            id="missionName"
            placeholder="Edit the mission title"
            style="width: 100%"
            name="Mission name"
            v-model="editMiniMissionTitle"
          />
          <label
            for="missionDescription"
            style="font-size: 12px; font-weight: 600"
            >Description</label
          >
          <textarea
            class="appInput"
            id="missionDescription"
            style="
              width: 100%;
              margin: 5px 0 0 0 !important;
              height: 80px !important;
              border-radius: 5px !important;
              padding: 20px !important;
              border: 1px solid #dedede;
              color: #7a615a;
              background: #fafafa;
              font-size: 14px;
              box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
            "
            v-model="editMiniMissionInstructions"
          ></textarea>

          <div class="flex mx-auto justify-center items-center mb-4 mt-6">
            <div class="text-center text-xs mr-5">Reward Type:</div>
            <div
              v-if="editGetRewardType == 'money'"
              class="activity_item__amount flex items-center"
            >
              <span class="text-[#56B897] text-[0.70rem] mr-[2px] font-bold"
                >+ {{ editMiniMissionMoney }}
              </span>
              <img
                src="../../assets/images/reward_money.svg"
                class="w-[16px] h-[16px] mt-[-2px]"
                alt="Money"
              />
            </div>
            <div
              v-if="editGetRewardType == 'points'"
              class="activity_item__amount flex items-center"
            >
              <span class="text-[#58C1E1] text-[0.70rem] mr-[2px] font-bold"
                >+ 25
              </span>
              <img
                src="../../assets/images/reward_points.svg"
                class="w-[13px] h-[13px] mt-[-2px]"
                alt="Points"
              />
            </div>
            <div
              v-if="editGetRewardType == 'wishlist'"
              class="activity_item__amount flex items-center"
            >
              <span class="text-[#EC6C6B] text-[0.70rem] mr-[2px] font-bold"
                >+</span
              >
              <img
                src="../../assets/images/reward_wishlist.svg"
                class="w-[13px] h-[13px]"
                alt="Wishlist"
              />
            </div>
          </div>

          <div class="form__options" style="margin-bottom: 20px">
            <div class="form__answer">
              <input
                :checked="editGetRewardType == 'points'"
                @click="editChangeMissionRewardType('points')"
                class="missionCheckbox"
                id="match_4"
                name="match"
                type="radio"
                value="guy"
              />
              <label
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                for="match_4"
              >
                <div>
                  Sonik Pocket<br />
                  Points
                  <p class="text-xs" style="font-size: 12px">+25</p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[2px]"
                    src="../../assets/images/mission-rewards/reward_points.svg"
                  />
                </div>
              </label>
            </div>

            <div class="form__answer" style="margin: 0 10px">
              <input
                :checked="editGetRewardType == 'money'"
                :disabled="$store.state.balance <= 0 || !modulrStatement"
                @change="editChangeMissionRewardType('money')"
                class="missionCheckbox"
                id="match_5"
                name="match"
                type="radio"
                value="girl"
              />
              <label
                :style="[
                  $store.state.balance <= 0 || !modulrStatement
                    ? { opacity: '.5' }
                    : {},
                ]"
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                for="match_5"
              >
                <div>
                  Money
                  <p class="text-xs" style="font-size: 11px">
                    Available balance
                  </p>
                  <p
                    style="margin-top: -1px; font-weight: bold; font-size: 12px"
                  >
                    &pound;{{ formatPrice(guardianBalance) }}
                  </p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[4px]"
                    src="../../assets/images/mission-rewards/reward_money.svg"
                  />
                </div>
              </label>
            </div>

            <div class="form__answer">
              <input
                :checked="editGetRewardType == 'wishlist'"
                @change="editChangeMissionRewardType('wishlist')"
                class="missionCheckbox"
                id="match_6"
                name="match"
                type="radio"
                value="cat"
              />
              <label
                class="lbl text-[13px] leading-tight p-3 rounded-lg border-none bg-[#EFEFEF] flex items-center justify-between"
                for="match_6"
              >
                <div>
                  Wishlist
                  <p class="text-xs leading-tight" style="font-size: 11px">
                    Select an item from <br />
                  </p>
                </div>
                <div
                  class="bg-[#58C1E1] rounded-full h-6 w-6 flex items-center justify-center"
                >
                  <img
                    alt=""
                    class="w-3 h-3 mt-[4px]"
                    src="../../assets/images/mission-rewards/reward_wishlist.svg"
                  />
                </div>
              </label>
            </div>
          </div>

          <div
            style="padding: 10px; margin-top: 30px"
            v-if="
              editGetRewardType == 'wishlist' &&
              missionWishlistGetter.length > 0
            "
          >
            <div class="header_container">
              <div class="wishlist_header">
                <div class="wishlist_header__title">Wishlist items</div>
              </div>
            </div>

            <div
              style="height: 200px; overflow: auto; margin-top: 10px"
              v-bind:class="{
                wishlistActive: wishlistToogleButtonState,
              }"
            >
              <div
                :key="index"
                class="wishlist_answer"
                v-for="(item, index) in missionWishlistGetter"
              >
                <input
                  :checked="editSelectedWishlistItem == item.id"
                  :id="item.id * 3"
                  :value="item.id"
                  @change="showWishlistItem(item.id)"
                  name="wishlistItem"
                  type="radio"
                />
                <label :for="item.id * 3" class="wishlist_label">
                  <img
                    :src="resolveImage(item.default_picture_link)"
                    alt=""
                    height="50"
                    width="50"
                  />
                  <div style="margin-left: 20px">
                    <p
                      class="wishlist_title"
                      style="
                        display: -webkit-box;
                        height: 20px;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                      "
                    >
                      {{ item.name }}
                    </p>
                    <p class="text-sm">&pound;{{ formatPrice(item.price) }}</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div
            class="p-4 text-xs text-center"
            v-else-if="editGetRewardType == 'wishlist'"
          >
            Your child has not added any wishlist items. Please remind
            <!-- {{ childUsername }} to add items to their wishlist. -->
          </div>

          <div
            class="text-xs font-light mt-4"
            v-if="editGetRewardType == 'money'"
          >
            Reward Amount
          </div>
          <AppInput
            @input="inputValidation"
            class="missions__Money"
            :min="1"
            pattern="[0-9\.]+"
            placeholder="How much £ will receive?"
            style="margin-top: 5px !important; margin-bottom: 0 !important"
            type="number"
            v-if="editGetRewardType == 'money'"
            v-model="editMiniMissionMoney"
          />

          <div
            class="addMissionRewardType flex items-center justify-center flex-col mt-4"
          >
            <div class="">
              <div
                class="flex items-center justify-center mb-[10px] text-[13px] mt-[10px] font-bold"
              >
                Reward visible?
              </div>
            </div>

            <toggle-button
              :color="{
                checked: '#56B897',
                unchecked: '#EC6C6B',
              }"
              :font-size="11"
              :height="25"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :sync="true"
              :value="editIsRewardVisible"
              :width="65"
              @change="editChangeRewardVisibility()"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <button
          @click="deleteMission(sheetMissionDetails.id)"
          class="bg-[#D76261] rounded py-2 px-3 text-white text-xs font-bold"
        >
          Delete Mission
        </button>
        <button
          @click="editMiniMission"
          class="bg-[#58C1E1] rounded-[100px] py-2 px-3 text-white text-xs font-bold"
        >
          Apply Changes
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="transactionsSheet" :on-close="closeSheetTransactions">
      <div slot="title">Transaction History</div>
      <TransactionList
        @openSheetTransactionDetails="openSheetTransactionDetails"
        :transactions="guardianTransactionsGetter"
      />
    </Sheet>

    <Sheet :show.sync="showPaymentSheet" :on-close="closePaymentSheet">
      <div slot="left-side">
        <div class="w-[25px] h-[27px]"></div>
      </div>
      <div slot="title">
        <img
          src="../../assets/images/logo-color.png"
          class="h-[35px] mt-[10px]"
          alt=""
        />
      </div>

      <div class="my-[20px]">
        <div class="font-semibold text-[#49AACB] mb-[28px]">Methods</div>

        <ul>
          <li
            class="border-b border-[#EBEBEB] py-[11px] cursor-pointer"
            @click="openStripeSheet"
          >
            <button class="flex items-center">
              <div
                class="bg-[#36D5F2] w-[40px] h-[40px] rounded-full flex items-center justify-center mr-[16px]"
              >
                <img
                  src="../../assets/card.svg"
                  class="w-[20px] h-[18px]"
                  alt=""
                />
              </div>
              Credit or Debit Card
            </button>
          </li>
        </ul>

        <div
          class="text-center text-[#909090] text-[10px] leading-[13px] mt-[20px]"
        >
          All transactions are non-refundable once the transaction is complete.
          Please read our terms and conditions for more information.
        </div>
      </div>
    </Sheet>

    <Sheet :show.sync="showStripeSheet" :on-close="closeModulrSheet">
      <div slot="left-side">
        <div class="w-[25px] h-[27px]"></div>
      </div>
      <div slot="title">
        <img
          src="../../assets/images/logo-color.png"
          class="h-[35px] mt-[10px]"
          alt=""
        />
      </div>

      <div class="my-[20px]">
        <div class="text-[#49AACB] font-semibold block mb-[20px]">
          Add money
        </div>

        <div class="text-[#AAAAAA] mb-[20px]">
          You have &pound;{{ formatPrice(guardianBalance) }} in your wallet
        </div>

        <div class="stripe-input">
          <span class="text-[#AAAAAA]">Add</span>
          <div class="currency-input">
            <input
              ref="stripeAmountRef"
              type="tel"
              name="price"
              placeholder="0"
              v-model="stripeAmount"
              style="width: 100%"
              @keypress="stripTheGarbage($event)"
              required
              autofocus
            />
            <span class="currency-symbol">&pound;</span>
          </div>
        </div>
      </div>

      <div class="mt-auto">
        <button
          :disabled="stripeAmount == ''"
          @click="stripePayment"
          :class="
            stripeAmount == ''
              ? 'bg-[#58C1E1] rounded-[100px] py-3 px-3 text-white font-bold opacity-50 w-full cursor-not-allowed'
              : ''
          "
          class="bg-[#58C1E1] rounded-[100px] py-3 px-3 text-white font-bold active:bg-[#4AA8C0] w-full"
        >
          Continue
        </button>
        <div class="flex justify-around mt-4">
          <button
            @click="addDefaultStripeAmount(3)"
            class="px-3 font-bold text-[#58C1E1]"
          >
            &pound;3
          </button>
          <button
            @click="addDefaultStripeAmount(5)"
            class="px-3 font-bold text-[#58C1E1]"
          >
            &pound;5
          </button>
          <button
            @click="addDefaultStripeAmount(10)"
            class="px-3 font-bold text-[#58C1E1]"
          >
            &pound;10
          </button>
          <button
            @click="addDefaultStripeAmount(20)"
            class="px-3 font-bold text-[#58C1E1]"
          >
            &pound;20
          </button>
        </div>
      </div>
    </Sheet>

    <Sheet
      :show.sync="transactionDetailsSheet"
      :on-close="closeSheetTransactionDetails"
    >
      <div slot="title">Transaction Details</div>
      <TransactionDetails :transaction="transactionDetailsObject" />
    </Sheet>
  </div>
</template>
<script>
import AppDashboardActivities from "../../components/AppDashboardActivities";
import swal from "sweetalert";
import { mapGetters, mapActions } from "vuex";
import config from "../../config";
import AppModulrBalance from "../../components/AppModulrBalance";
import TransactionList from "../../components/transactions/TransactionList";
import TransactionDetails from "../../components/transactions/TransactionDetails";
import axios from "axios";
import status from "../../utils/statusCode";

export default {
  components: {
    AppInput: () =>
      import(/* webpackChunkName: "AppInput" */ "../../components/AppInput"),
    Sheet: () =>
      import(/* webpackChunkName: "Sheet" */ "../../components/Sheet"),
    AppDashboardActivities,
    AppModulrBalance,
    TransactionList,
    TransactionDetails,
  },
  data() {
    return {
      temp: {
        old_unique_date: null,
      },
      stripeAmount: "",
      showStripeSheet: false,
      showPaymentSheet: false,
      editMiniMissionTitle: "",
      editIsRewardVisible: false,
      editSelectedWishlistItem: null,
      wishlistToogleButtonState: false,
      editMiniMissionMoney: "",
      editGetRewardType: "",
      edit_mission_start_hour: "00:00",
      edit_mission_end_hour: "23:59",
      editMissionHoursType: true,
      editCalendarData: {},
      editCalendarConfigs: {
        dateFormat: "dd-mm-yyyy",
        isMultipleDatePicker: true,
        hiddenElements: ["leftAndRightDays"],
        disabledDates: ["beforeToday"],
      },
      editCalendarToogleButtonState: false,
      editMiniMissionInstructions: "",
      editMissionDates: {},
      sheetMissionEdit: false,
      expandedId: null,
      modulrAccountNumber: null,
      modulrAccountName: null,
      modulrSortcode: "04-00-72",
      missionStartTime: null,
      missionEndTime: null,
      sheetMissionPendingRewardConfirm: false,
      sheetMissionDetailsType: null,
      sheetMissionDetails: [],
      sheetModulr: true,
      missionDetailsSheet: false,
      transactionsSheet: false,
      transactionDetailsSheet: false,
      transactionDetailsObject: false,
      active: false,
      componentKey: 0,
      isLoading: true,
      isAlternate: false,
      trusted: false,
      nonActive: false,
      child: {
        name: "",
        username: "",
        date: "",
        password: "",
        passwordConfirm: "",
        file: {},
      },
      activeAvatar: true,
      phoneNumber: "",
      trustedFirstName: "",
      trustedLastName: "",
      trustedUsername: "",
      trustedEmail: "",
      trustedRelationship: "",
      changeTodefault: false,
      src: "",
      guardian: {
        name: "",
        date: "",
        street: "",
        town: "",
        number: "",
        postcode: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "dashboardActivities",
      "modulrStatusGetter",
      "pendingRewardMissionsGetter",
      "guardianBalance",
      "user",
      "modulrStatusGetter",
      "dashboardMissionsInprogressGetter",
      "dashboardMissionsCompletedGetter",
      "dashboardMissionsFailedGetter",
      "dashboardMissionsExpiredGetter",
      "guardianTransactionsGetter",
      "miniMissionsLengthGetter",
      "guardianBalance",
      "getSetupChildrens",
    ]),
    modulrStatement() {
      switch (this.modulrStatusGetter) {
        case 2:
          return true;
        case 3:
          return true;
        case 6:
          return true;
        default:
          return false;
      }
    },
    missionStartHourSplit() {
      var hour =
        this.sheetMissionDetails.date_times_mission[0].start_time.split(":");
      return `${hour[0]}:${hour[1]}`;
    },
    missionEndHourSplit() {
      var hour =
        this.sheetMissionDetails.date_times_mission[0].end_time.split(":");
      return `${hour[0]}:${hour[1]}`;
    },
    addFundsStyle() {
      if (
        this.modulrStatusGetter == 3 ||
        this.modulrStatusGetter == 2 ||
        this.modulrStatusGetter == 6
      ) {
        return `linear-gradient(180deg, #36d5f2 0%, #00ccff 100%)`;
      } else {
        return `#B9B9B9`;
      }
    },
    strokeColor() {
      if (
        this.modulrStatusGetter == 3 ||
        this.modulrStatusGetter == 2 ||
        this.modulrStatusGetter == 6
      ) {
        return `#36d5f2`;
      } else {
        return `#B9B9B9`;
      }
    },
    filteredInProgressActivities() {
      return this.dashboardMissionsInprogressGetter.filter((parent) => {
        return parent.date_times_mission.find(
          (item) => item.start_date == this.currentDate
        );
      });
    },
    filteredCompletedActivities() {
      return this.dashboardMissionsCompletedGetter.filter((parent) => {
        return parent.date_times_mission.find(
          (item) => item.start_date == this.currentDate
        );
      });
    },
    filteredFailedActivities() {
      return this.dashboardMissionsFailedGetter.filter((parent) => {
        return parent.date_times_mission.find(
          (item) => item.start_date == this.currentDate
        );
      });
    },
    filteredExpiredActivities() {
      return this.dashboardMissionsExpiredGetter.filter((parent) => {
        return parent.date_times_mission.find(
          (item) => item.start_date == this.currentDate
        );
      });
    },
    currentDate() {
      let today = new Date();
      return [
        today.getFullYear(),
        ("0" + (today.getMonth() + 1)).slice(-2),
        ("0" + today.getDate()).slice(-2),
      ].join("-");
    },
    openModulrUrl() {
      const token = localStorage.getItem("guardian_token");
      const userId = localStorage.getItem("guardian_user_id");
      return (
        config.HOST +
        "/modulr/send-validation?userId=" +
        userId +
        "&token=" +
        token
      );
    },
    days() {
      const map = {};

      for (const activity of this.guardianTransactionsGetter) {
        const day = activity.created_at.slice(0, 10);

        map[day] = map[day] || { created_at: day, activities: [] };
        map[day].activities.push(activity);
      }

      return Object.keys(map)
        .sort()
        .map((day) => map[day]);
    },
  },
  methods: {
    ...mapActions([
      "fetchDasboardActivity",
      "editMission",
      "fetchBalance",
      "deleteGuardianChild",
      "editChildAction",
      "addTrustedGuardian",
      "fetchUser",
      "fetchProfile",
      "fetchPendingRewardMissions",
      "TOAST",
      "doGiveRewardAction",
      "dontGiveRewardAction",
      "missionWishlistGetter",
      "fetchMissionWishlistAction",
      "deleteMiniMission",
      "fetchGuardianTransactionsAction",
    ]),
    focusStripeAmountInput() {
      if (this.showStripeSheet) {
        console.log(this);
        this.$refs.stripeAmountRef.focus();
      }
    },
    addDefaultStripeAmount(value) {
      this.stripeAmount = value;
    },
    stripePayment() {
      //add params to url and then clear them after 1 second
      this.$router.push({
        path: "/",
        query: {
          amount: this.stripeAmount,
          userId: this.user.id,
        },
      });

      setTimeout(() => {
        this.$router.push({
          path: "/",
        });
      }, 1000);
    },
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    async openSheetTransactions() {
      const token = localStorage.getItem("guardian_token");
      const userId = localStorage.getItem("guardian_user_id");
      const family = localStorage.getItem("guardian_family_id");

      this.isLoading = true;
      this.isAlternate = true;

      const response = await axios.get(
        "/transaction?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      );
      const { statusCode } = response.data;
      if (status.success(statusCode)) {
        this.$store.commit("FETCH_GUARDIAN_TRANSACTIONS", response.data.data);
        this.transactionsSheet = true;
        this.isLoading = false;
        this.isAlternate = false;
      } else if (status.invalidToken(statusCode)) {
        this.deleteStorage();
        this.isLoading = false;
        this.isAlternate = false;
      } else if (status.error(statusCode)) {
        this.isLoading = false;
        this.isAlternate = false;
        this.TOAST("Something went wrong! Please contact our support team.");
      }
    },
    closeSheetTransactions() {
      this.transactionsSheet = false;
    },
    openSheetTransactionDetails(id) {
      this.transactionDetailsObject = this.guardianTransactionsGetter.filter(
        (item) => item.id == id
      )[0];
      this.transactionsSheet = false;
      this.transactionDetailsSheet = true;
      console.log(
        this.guardianTransactionsGetter.filter((item) => item.id == id)[0]
      );
    },
    closeSheetTransactionDetails() {
      this.transactionDetailsSheet = false;
    },
    deleteMission(id) {
      swal({
        title: "Are you sure?",
        text: "This mission will be deleted!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.closeSheetMissionEdit();
          this.deleteMiniMission(id);
        }
      });
    },
    editChangeMissionRewardType(type) {
      this.editGetRewardType = type;
    },
    inputValidation(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }

      if (this.miniMissionMoney > this.$store.state.balance) {
        alert("Not enough money");
      }
    },
    editChangeRewardVisibility() {
      this.editIsRewardVisible = !this.editIsRewardVisible;
    },
    showWishlistItem(item) {
      this.editSelectedWishlistItem = item;
    },
    editGetScheduleStartTimeCalendar(event) {
      this.edit_mission_start_hour = event;
    },
    editGetScheduleEndTimeCalendar(event) {
      this.edit_mission_end_hour = event;
    },
    editChangeMissionHoursType() {
      const editStartTime = this.edit_mission_start_hour_switch;
      const editEndTime = this.edit_mission_end_hour_switch;

      this.editMissionHoursType = !this.editMissionHoursType;
      if (this.editMissionHoursType) {
        this.edit_mission_start_hour = "00:00";
        this.edit_mission_end_hour = "23:59";
      } else {
        this.edit_mission_start_hour = editStartTime;
        this.edit_mission_end_hour = editEndTime;
      }
    },
    editReturnDate() {
      const datesArray = [];
      const startHour = this.mission_start_hour;
      const endHour = this.mission_end_hour;

      this.editCalendarData.selectedDates.forEach(function (value) {
        const datesFormat = {
          start_datetime: value.date + " " + startHour,
          end_datetime: value.date + " " + endHour,
        };
        datesArray.push(datesFormat);
      });

      this.editDateCalendar = datesArray;
    },
    editShowCalendar() {
      this.editCalendarToogleButtonState = true;
      this.editMissionShowInputs = false;
    },
    editCloseCalendar() {
      this.editCalendarToogleButtonState = false;
      this.editMissionShowInputs = true;
    },
    parseDate(dateStr, format) {
      const regex = format
        .toLocaleLowerCase()
        .replace(/\bd+\b/, "(?<day>\\d+)")
        .replace(/\bm+\b/, "(?<month>\\d+)")
        .replace(/\by+\b/, "(?<year>\\d+)");

      const parts = new RegExp(regex).exec(dateStr) || {};
      const { year, month, day } = parts.groups || {};
      const finalDate =
        parts.length === 4 ? new Date(year, month - 1, day) : undefined;

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const finday = finalDate.getDate();
      const finmonthIndex = finalDate.getMonth();
      const finyear = finalDate.getFullYear();

      return `${finday} ${monthNames[finmonthIndex]} ${finyear}`;
    },
    editMiniMission() {
      if (
        this.editMiniMissionTitle.length <= 2 ||
        this.editMiniMissionInstructions.length <= 2
      ) {
        this.modalHasError = true;
        this.addError = "Complete all fields.";
      } else if (
        (this.editGetRewardType == "money" && this.editMiniMissionMoney == 0) ||
        (this.editGetRewardType == "money" && this.editMiniMissionMoney == "")
      ) {
        this.modalHasError = true;
        this.addError = "Reward sum cannot be 0 or empty.";
      } else if (
        this.editGetRewardType == "money" &&
        this.$store.state.balance < this.editMiniMissionMoney
      ) {
        this.modalHasError = true;
        this.addError = "You do not have enough funds.";
      } else {
        const finalTimestamps = [];
        const startHour = this.edit_mission_start_hour;
        const endHour = this.edit_mission_end_hour;

        this.editMissionDates.forEach(function (value) {
          const datesArr = {
            start_datetime: value.date + " " + startHour,
            end_datetime: value.date + " " + endHour,
          };
          finalTimestamps.push(datesArr);
        });

        // console.log("from editMissionDates", this.editMissionDates)
        var self = this;
        if (Object.keys(this.editCalendarData).length > 0) {
          if (this.editCalendarData.selectedDates.length > 0) {
            this.editReturnDate();
            this.editCalendarData.selectedDates.forEach(function (value) {
              const datesArr2 = {
                start_datetime: value.date + " " + startHour,
                end_datetime: value.date + " " + endHour,
              };

              //Daca se selecteaza aceasi data cu data existenta, nu se mai face push in array sa se trimita la server
              const filterDuplicateDates = self.editMissionDates.find(
                (item) => item.date == value.date
              );
              filterDuplicateDates == undefined
                ? finalTimestamps.push(datesArr2)
                : null;
            });
          }
        }

        const {
          editMiniMissionTitle,
          editMiniMissionInstructions,
          editMiniMissionMoney,
        } = this;

        if (this.editGetRewardType == "money") {
          if (
            editMiniMissionTitle &&
            editMiniMissionInstructions &&
            editMiniMissionMoney
          ) {
            const mission = {
              categoryId: 1,
              mission_id: this.editMiniMissionId,
              name: editMiniMissionTitle,
              description: editMiniMissionInstructions,
              sum_money: editMiniMissionMoney,
              sum_points: 25,
              reward_type: "money",
              time_condition: finalTimestamps,
              reward_hidden: this.editIsRewardVisible,
            };
            this.editMission(mission);
            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          }
        } else if (this.editGetRewardType == "wishlist") {
          if (
            editMiniMissionTitle &&
            editMiniMissionInstructions &&
            this.missionWishlistGetter.length > 0
          ) {
            const mission = {
              categoryId: 1,
              mission_id: this.editMiniMissionId,
              name: editMiniMissionTitle,
              description: editMiniMissionInstructions,
              sum_money: 0,
              sum_points: 25,
              reward_type: "wishlist",
              product_id: this.editSelectedWishlistItem,
              time_condition: finalTimestamps,
              reward_hidden: this.editIsRewardVisible,
            };

            this.editMission(mission);
            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          } else {
            this.addError =
              "You selected Wishlist as reward type, but there are no wishlist items selected. Please choose another reward type!";
            this.modalHasError = true;
          }
        } else if (this.editGetRewardType == "points") {
          if (editMiniMissionTitle && editMiniMissionInstructions) {
            const mission = {
              categoryId: 1,
              mission_id: this.editMiniMissionId,
              name: editMiniMissionTitle,
              description: editMiniMissionInstructions,
              sum_money: 0,
              sum_points: 25,
              reward_type: "points",
              time_condition: finalTimestamps,
              reward_hidden: this.editIsRewardVisible,
            };
            this.editMission(mission);
            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          }
        }
      }
    },
    removeHourItem(index) {
      this.editMissionDates.splice(index, 1);
    },
    openSheetMissionEdit() {
      this.sheetMissionEdit = true;
    },
    closeSheetMissionEdit() {
      this.sheetMissionEdit = false;
      this.editCalendarToogleButtonState = false;
    },
    openMissionDetails(mission) {
      if (
        mission.date_times_mission.filter(
          (item) => item.status == "in_progress"
        ).length > 0
      ) {
        this.openMiniEdit(mission);
      } else {
        this.sheetMissionDetails = mission;
        this.missionDetailsSheet = true;
      }
    },
    openMiniEdit(mission) {
      this.sheetMissionDetails = mission;
      this.openSheetMissionEdit();
      this.addError = "";
      this.editMiniMissionId = mission.id;
      this.editMiniMissionChildId = mission.child_id;
      this.editMiniMissionTitle = mission.name;
      this.editMiniMissionInstructions = mission.description;
      this.editMiniMissionMoney = Number(mission.sum_money).toFixed();
      this.editGetRewardType = mission.reward_type;
      this.editSelectedWishlistItem = mission.product_id;
      this.edit_mission_start_hour_switch =
        mission.date_times_mission[0].start_time;
      this.edit_mission_end_hour_switch =
        mission.date_times_mission[0].end_time;
      this.editIsRewardVisible = mission.is_reward_visible === 0 ? false : true;

      if (
        mission.date_times_mission[0].start_time == "00:00:00" &&
        mission.date_times_mission[0].end_time == "23:59:00"
      ) {
        this.editMissionHoursType = true;
      } else {
        this.editMissionHoursType = false;
        this.edit_mission_start_hour = mission.date_times_mission[0].start_time;
        this.edit_mission_end_hour = mission.date_times_mission[0].end_time;
      }

      this.editMissionDates = {};
      const editCalendarDates = [];

      mission.date_times_mission.forEach(function (date) {
        if (date.start_date) {
          const dateStructure = {
            date: date.start_date,
            start_hour: date.start_time,
            end_hour: date.end_time,
          };
          editCalendarDates.push(dateStructure);
        }
      });

      this.editMissionDates = editCalendarDates;
      console.log(this.editMissionDates);
    },
    closeMiniEdit() {
      this.editMiniModal = false;
      this.editCalendarData = {};
      this.forceRerender();
      this.editMissionShowInputs = true;
      this.editCalendarToogleButtonState = false;
      this.modalHasError = false;
      this.addError = "";
    },
    monthToName(str) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]; //array of months
      const day = str.split("-")[2]; //get day from date
      const month = str.split("-")[1]; //get month from date
      const year = str.split("-")[0]; //get year from date
      return `${day} ${months[month - 1]} ${year}`; //return day and month
    },
    expandPendingRewardMission(id) {
      this.expandedId = id;
    },
    convertTime(time) {
      var hourEnd = time.indexOf(":");
      var H = +time.substr(0, hourEnd);
      var h = H % 12 || 12;
      var ampm = H < 12 ? "am" : "pm";
      return h + time.substr(hourEnd, 3) + ampm;
    },
    onCopy: function (e) {
      this.TOAST("Copied successfully to the clipboard: " + e.text);
    },
    onError: function (e) {
      this.TOAST("Failed to copy the text to the clipboard");
      console.log(e);
    },
    giveReward(id, sum) {
      let missionSum = parseInt(sum);

      const missionData = {
        address_id: this.selectedAddressId,
        mission_id: id,
      };

      if (this.type == "money") {
        if (this.guardianBalance > 0 && this.guardianBalance >= missionSum) {
          this.doGiveRewardAction(missionData);
          this.closeSheetPendingMissionConfirm();
        } else {
          this.closeSheetPendingMissionConfirm();
          this.TOAST(
            "You do not have enough money to spend on this mission. Please add money to your account!"
          );
        }
      } else {
        this.doGiveRewardAction(missionData);
        this.closeSheetPendingMissionConfirm();
      }
    },
    givePartialReward(id, sum) {
      let missionSum = parseInt(sum);
      if (this.type == "money") {
        if (this.balance > 0 && this.balance >= missionSum) {
          this.dontGiveRewardAction(id);
          this.closeSheetPendingMissionConfirm();
        } else {
          this.closeSheetPendingMissionConfirm();
          this.TOAST(
            "You do not have enough money to spend on this mission. Please add money to your account!"
          );
        }
      } else {
        this.dontGiveRewardAction(id);
        this.closeSheetPendingMissionConfirm();
      }
    },
    stagesCompleted(array) {
      return array.filter(function (item) {
        return item.status === "completed";
      }).length;
    },
    rewardPercent(value, total) {
      var calcPercent = function (v, t) {
        return (100 * v) / t;
      };

      return calcPercent(value, total);
    },
    partialReward(price, percent) {
      return (price * percent) / 100;
    },
    closeSheetPendingMissionConfirm() {
      this.sheetMissionPendingRewardConfirm = false;
    },
    openSheetPendingMissionConfirm() {
      this.sheetMissionPendingRewardConfirm = true;
    },
    pendingRewardConfirm(mission) {
      this.sheetMissionDetails = mission;
      this.openSheetPendingMissionConfirm();
    },
    missionDetails(mission, type) {
      this.sheetMissionDetails = mission;
      this.sheetMissionDetailsType = type;
      this.openSheetMissionDetails();
    },
    openSheetMissionDetails() {
      this.missionDetailsSheet = true;
    },
    closeSheetMissionDetails() {
      this.missionDetailsSheet = false;
    },
    openPaymentSheet() {
      this.showPaymentSheet = true;
      this.isClassAdded = true;
    },
    closePaymentSheet() {
      this.showPaymentSheet = false;
      isClassAdded: false;
    },
    openStripeSheet() {
      this.showPaymentSheet = false;
      this.showStripeSheet = true;
      this.stripeAmount = "10";
    },
    closeStripeSheet() {
      this.showStripeSheet = false;
      this.stripeAmount = "";
    },
    openModulrSheet() {
      this.$router.replace("/guardian/add-funds");
      // this.showStripeSheet = true;
      // this.stripeAmount = "10";
      // alert("Modulr is not available at the moment")
    },
    closeModulrSheet() {
      // this.sheetModulr = false;
      this.showStripeSheet = false;
      this.stripeAmount = "";
    },
    openModular() {
      const token = localStorage.getItem("guardian_token");
      const userId = localStorage.getItem("guardian_user_id");
      window.location =
        config.HOST +
        "/modulr/send-validation?userId=" +
        userId +
        "&token=" +
        token;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    resolveImage(src) {
      return config.HOST + src;
    },
  },
  // mounted() {
  //     Promise.all([
  //         this.fetchUser(),
  //         this.fetchProfile(),
  //         this.fetchPendingRewardMissions(),
  //         this.fetchDasboardActivity(),
  //         this.fetchBalance(),
  //     ]).then(() => {
  //         this.isLoading = false;
  //     });
  // },
  watch: {
    editGetRewardType: function (val) {
      if (val == "wishlist") {
        console.log(this.editMiniMissionChildId);
        this.fetchMissionWishlistAction(this.editMiniMissionChildId);
      }
    },
    showStripeSheet: function (val) {
      if (val) {
        this.focusStripeAmountInput();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#recent {
  // To remove recent activity
  display: none;
  h1 {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.02rem;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  }

  .recent_container {
    margin-top: 20px;
  }

  .activity_item {
    width: 100%;
    height: 80px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    background: white;
    padding: 15px;
    border-radius: 5px;
    border-bottom-right-radius: 15px;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease-in-out !important;

    &:active {
      opacity: 0.6;
    }

    &__container {
      display: flex;
      align-items: flex-start;
    }

    &__title {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.01rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }

    &__description {
      font-size: 11px;
      color: #9b9b9b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 185px;
    }

    &__amount {
      position: absolute;
      top: 10px;
      right: 15px;
      color: var(--main);
      font-weight: 600;
      font-size: 11px;
    }

    &__icon {
      margin-right: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &__name {
        font-size: 11px;
        color: #9b9b9b;
        white-space: nowrap;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
}

#family {
  margin-top: 30px;

  h1 {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.02rem;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  }

  .family_container {
    margin-top: 20px;
    background: white;
    padding: 25px;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .family_text {
    color: var(--ternary);

    &__title {
      font-weight: bold;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.03rem;
      color: var(--main);
    }

    &__amount {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.02rem;
      color: var(--main);
      line-height: 20px;
    }
  }

  .family_button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      opacity: 0.8;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.homeNotFoundActivities {
  font-size: 18px;
}

.dashboardHome__center__members__children,
.dashboardHome__center__members__family {
  height: 200px;
  width: 100%;
  background: #f8f5ed;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden !important;
  overflow-x: scroll;
}

.dashboardHome__center__members__children::-webkit-scrollbar {
  background: transparent !important;
  height: 4px !important;
}

.dashboardHome__center__members__family::-webkit-scrollbar {
  background: transparent !important;
  height: 4px !important;
}

.dashboardHome__center__members__children::-webkit-scrollbar,
.dashboardHome__center__members__family::-webkit-scrollbar {
  display: none !important;
}

.dashboardHome__center__members__children__title,
.dashboardHome__center__members__family__title {
  display: block;
  padding-top: 30px;
  margin-left: 30px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #7a615a;
}

.guardianAddMission {
  margin-top: 15px;
}

.dashboardHome__sideMoneyMission {
  flex-grow: 1;
}

.guardiansTypes {
  display: flex;
  flex-direction: row;
}

.guardiansTypes__second {
  margin-left: 50px;
}

.dashboardHome__center__members__children__btn {
  margin-top: 10px;
  margin-left: 25px !important;
  display: flex;
  flex-direction: row;
}

.dashboardHome__center__members__children__btn__add {
  margin-bottom: 5px;
  margin-right: 20px;
}

.appForm__avatars {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

.appForm__avatarText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #7a615a;
  margin-top: 50px;
}

.guardiansTypes__functionality {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appGuardianType {
  margin-top: 30px;
}

.modalAddGuardian {
  width: 270px;
  margin: 0 auto;
  margin-top: 30px;
}

.guardianTypesExplication__nonActive {
  margin-top: 30px;
}

.guardianTypes__nonActive__functionality {
  display: flex;
  flex-direction: column;
}

.guardianTypes__nonActive__address {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.guardianTypes__nonActive__address > input {
  width: 180px;
}

@media screen and (max-width: 850px) {
  .homeNotFoundActivities {
    text-align: center;
  }

  .dashboardHome__sideMoneyMission {
    margin-left: 0 !important;
  }

  .dashboardHome__center__recent__text {
    text-align: center;
    margin-left: 0 !important;
  }

  .dashboardHome__center__recent {
    height: auto;
  }

  .activityContainer {
    padding: 30px 0;
    height: auto;
    flex-direction: column;
  }
}

@media only screen and (max-width: 3500px) and (min-width: 1515px) {
  .dashboardHome__center__recent {
    height: calc(100vh - 310px);
  }
  .dashboardHome__center__recent,
  .dashboardHome__center__members {
    width: calc(100vw - 585px);
  }
  .dashboardHome__center__members__children {
    width: 49.5%;
  }
  .dashboardHome__center__members__family {
    width: 100%;
  }
  .addFundsGuardian {
    height: calc(100vh - 310px);
  }
  .guardianAddMission {
    height: 300px;
  }
}

@media screen and (max-width: 1187px) {
  .dashboardHome {
    &__center__mobile {
      display: flex;
    }

    &__sideMoneyMission {
      display: none;
    }

    &__center {
      &__members {
        &__children,
        &__family {
          width: calc(50% - 10px);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboardHome {
    &__center {
      &__members {
        flex-direction: column;

        &__children,
        &__family {
          width: 100%;
        }

        &__family {
          margin-left: 0;
        }
      }
    }
  }
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}

.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}

.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

.not-margin {
  margin: 0px;
  font-weight: normal;
  padding: 10px;
}

.con-form {
  width: 100%;
}

.con-form .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.con-form .flex a {
  font-size: 0.8rem;
  opacity: 0.7;
}

.con-form .flex a:hover {
  opacity: 1;
}

.con-form .vs-checkbox-label {
  font-size: 0.8rem;
}

.con-form .vs-input-content {
  margin: 10px 0px;
  width: calc(100%);
}

.con-form .vs-input-content .vs-input {
  width: 100%;
}

.footer-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100%);
}

.footer-dialog .new {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  font-size: 0.7rem;
}

.footer-dialog .new a {
  color: rgba(var(--vs-primary), 1) !important;
  margin-left: 6px;
}

.footer-dialog .new a:hover {
  text-decoration: underline;
}

.footer-dialog .vs-button {
  margin: 0px;
}

@media only screen and (max-width: 820px) {
  .activityContainer {
    padding: 10px 0 30px 0;
  }
  .home-childrens .col-md-12,
  .home-childrens .col-md-6 {
    margin: 0 !important;
  }
  .homeNotFoundActivities {
    font-size: 14px;
  }
  .activitiesImg {
    display: none;
  }
  .dashboardHome__center__recent {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .addButton__plus img {
    width: 20px !important;
    height: 20px !important;
  }
  .appMemberListItem img {
    height: 60px;
    width: 60px;
    border: 2px solid #36d5f2;
  }
  .appMemberListItem {
    padding: 0 15px;
  }
  .appMemberListyItem:first-child {
    padding-left: 0 !important;
  }
  .dashboardHome__center__members__children {
    height: auto;
    padding-bottom: 20px;
    border-radius: 20px;
  }
  .dashboardHome__center__members__children__title {
    margin-bottom: 10px;
  }
  .dashboardHome__center__members__children::-webkit-scrollbar {
    display: none;
  }
  .dashboardHome__center__members__family::-webkit-scrollbar {
    display: none;
  }
  .addButton__family {
    margin-top: 0;
  }
  .dashboardHome__center__members__family__title {
    margin-bottom: 10px;
  }
  .dashboardHome__center__members__family {
    height: auto;
    border-radius: 20px;
    padding-bottom: 20px;
  }
  .dashboardHome__center__recent {
    border-radius: 20px;
  }
  .dashboardHome__sideMoneyMission {
    margin-top: 0;
  }
  .homeSidebarSticky {
    padding-top: 0 !important;
  }
  .addFundsGuardian {
    border-radius: 20px;
    height: auto !important;
    &__balance {
      margin: 0;
      margin-top: 20px;
    }
    &__total {
      display: none;
    }
    &__addText {
      margin-top: 20px;
    }
  }
  .guardianAddMission {
    border-radius: 20px;
    height: auto;
    padding: 20px 0;
    margin-bottom: 0;
    &__title {
      font-size: 25px;
    }
    &__img {
      display: none;
    }
  }
  .welcomeCard {
    border-radius: 20px;
    width: 100%;
    padding: 0;
    &__main {
      border-radius: 10px;
      text-align: left;
      height: auto;
      width: 100%;
      padding: 15px 30px;
      margin-bottom: 10px;

      &__title {
        text-align: left;
        margin: 0;
        margin-top: 5px;
        line-height: 18px;
        font-size: 16px !important;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 900;
      }
      &__description {
        font-size: 12px !important;
        text-align: left;
        margin: 0;
      }
    }
  }
}

.dashboardHome__center__recent,
.dashboardHome__center__members {
  width: 100% !important;
}

.dashboardHome__center__members__children,
.addFundsGuardian,
.guardianAddMission {
  width: 100% !important;
}

.walletScroll {
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0.5rem;
}

.walletScroll__item {
  --stop: normal;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 250px;
  scroll-snap-align: start;
  scroll-snap-stop: var(--stop);
}

.walletScroll__item:last-child {
  margin-right: 0;
}

.walletScroll__item__2 {
  --stop: normal;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex: 0 0 150px;
  scroll-snap-align: start;
  scroll-snap-stop: var(--stop);
  background: #f8f5ed;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  height: 180px;
  padding: 10px;
  border-radius: 20px;
}

.walletScroll__item__2:last-child {
  margin-right: 0;
}

.walletScroll__item__3 {
  --stop: normal;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex: 0 0 250px;
  scroll-snap-align: start;
  scroll-snap-stop: var(--stop);
}

.walletScroll__item__3:last-child {
  margin-right: 0;
}

.stripe-input {
  min-height: 69px !important;
  color: var(--main) !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 16px;
  font-size: 14px;
  min-width: 50px;
  color: #01a4c0 !important;
  display: block;
  border: none !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: unset !important;

  .stripe-amount {
    font-size: 20px;
    font-weight: 700;
    height: 100%;
    position: relative;
    width: 20px;

    &:before {
      content: "$";
      position: absolute;
      top: 0;
      left: 5px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.stripe-amount::-webkit-outer-spin-button,
.stripe-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.stripe-amount[type="number"] {
  -moz-appearance: textfield;
}

.currency-input {
  position: relative;
  color: #01a4c0 !important;

  input {
    padding-left: 15px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    background: transparent !important;
    color: #01a4c0 !important;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: right;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: 600;
  }

  input:invalid ~ .currency-symbol,
  input:invalid {
    opacity: 0.5;
  }
}
.pending-missions {
  text-align: center;
  background-color: #f6a42c;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 45px;
  font-weight: 700;
}

.next {
  font-size: 20px;
  font-weight: 800;
}

// .step {
//   font-weight: 700;
//   display: inline-block !important;
//   border: 2px solid #01A4C0;
//   display: inline-block;
//   color: #01A4C0;
//   font-size: 120%;
//   padding: 25px;
//   margin: 5px;
//   border-radius: 30px;
//   text-align: center;
//   width: 100%;
//   box-shadow: 0 0 0 0 #01A4C0;
// 	transform: scale(1);
// 	animation: pulse 2s infinite;

// }
.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  min-width: 300px;
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: white;
  background: linear-gradient(90deg, #00c1e3 0%, #01a4c0 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(79, 209, 197, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #01a4c0;
  box-shadow: 0 0 60px rgba(0, 255, 203, 0.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.button:hover,
.button:focus {
  color: #313133;
  transform: translateY(-6px);
}

.button:hover::before,
.button:focus::before {
  opacity: 1;
}

.button::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #01a4c0;
  position: absolute;
  z-index: -2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}
.remove-ring {
  animation: 0 !important;
}
.button:hover::after,
.button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #01a4c0;
  }

  70% {
    transform: scale(0.96);
    box-shadow: 0 0 0 2px #01a4c0;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #01a4c0;
  }
}
.step-completed {
  border-color: #56b897;
  color: #56b897;
}
.top_up {
  margin-left: 5em;
}
.no_step {
  display: none;
}
.steps {
  margin-bottom: 2em;
}
</style>
