<template>
    <div>
        <AppModulrBalance :showTransactionsButton="false" />
        <template v-if="Object.keys(transactions).length > 0">
            <div
                v-for="transaction in transactions"
                :key="transaction.id"
                class="transaction"
                @click="openSheetTransactionDetails(transaction.id)"
            >
                <div
                    class="transaction__date"
                    v-html="groupByDate(transaction.created_at)"
                ></div>

                <div class="transaction__item">
                    <img
                        v-if="transaction.type === 'deposit'"
                        class="transaction__item__image"
                        src="../../assets/images/deposit-icon.svg"
                        alt=""
                    />
                    <img
                        v-if="transaction.type === 'pocket_money'"
                        class="transaction__item__image"
                        src="../../assets/images/pocket-money-icon.svg"
                        alt=""
                    />
                    <img
                        v-if="transaction.type === 'withdraw'"
                        class="transaction__item__image"
                        src="../../assets/images/withdraw-icon.svg"
                        alt=""
                    />

                    <div class="transaction__item__center">
                        <div
                            class="transaction__item__center__date"
                            v-html="
                                convertDate(transaction.created_at, 'full-date')
                            "
                        ></div>
                        <div class="transaction__item__center__type">
                            <span v-if="transaction.type === 'deposit'"
                                >Deposit</span
                            >
                            <span v-if="transaction.type === 'pocket_money'"
                                >Pocket Money</span
                            >
                            <span v-if="transaction.type === 'withdraw'"
                                >Withdraw</span
                            >
                        </div>
                        <div
                            v-if="transaction.type === 'deposit'"
                            class="transaction__item__center__user"
                        >
                            {{ transaction.bank_name }}
                        </div>
                    </div>

                    <div class="transaction__item__sum">
                        <span
                            v-if="transaction.sum >= 0"
                            style="color: #56b897"
                        >
                            {{ addStr(transaction.sum, 1, "&pound;") }}
                        </span>
                        <span v-else style="color: #ec6c6b">
                            {{ addStr(transaction.sum, 1, "&pound;") }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center">You have no transactions</div>
        </template>
    </div>
</template>
<script>
import AppModulrBalance from "../../components/AppModulrBalance";
export default {
    props: ["transactions"],
    data() {
        return {};
    },
    components: {
        AppModulrBalance,
    },
    methods: {
        openSheetTransactionDetails(id) {
            this.$emit("openSheetTransactionDetails", id);
        },
        addStr(str, index, stringToAdd) {
            return str >= 0
                ? stringToAdd + str
                : str.toString().substring(0, index) +
                      " " +
                      stringToAdd +
                      str.toString().substring(index, str.toString().length);
        },
        convertDate(date, type) {
            var monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            var date = new Date(date);
            var day = date.getDate();
            var monthIndex = date.getMonth();
            var year = date.getFullYear();
            return type === "full-date"
                ? `${day} ${monthNames[monthIndex]} ${year}`
                : `${monthNames[monthIndex]} ${year}`;
        },
        groupByDate(date) {
            var day = ("0" + new Date(date).getDate()).slice(-2);
            var year = new Date(date).getFullYear();
            var month = ("0" + (new Date(date).getMonth() + 1)).slice(-2);
            var date_data = `${year}-${month}`;

            if (this.old_unique_date === date_data) {
                return "";
            }

            return this.convertDate((this.old_unique_date = date_data), null);
        },
    },
};
</script>

<style lang="scss" scoped>
.transaction {
    &__date {
        &:not(:empty) {
            color: #000000;
            padding: 20px 0;
        }
    }
    &__item {
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        cursor: pointer;
        display: grid;
        font-size: 14px;
        grid-template-columns: repeat(5, 1fr);
        padding: 10px 30px 10px 0;
        position: relative;
        text-align: center;

        &:after {
            align-items: center;
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' stroke='%23cccccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.625 5.25 6.75 6.75-6.75 6.75'%3E%3C/path%3E%3C/svg%3E");
            display: flex;
            height: 20px;
            justify-content: flex-end;
            padding-top: 5px;
            position: absolute;
            right: 0;
            width: 20px;
        }

        &__image {
            grid-column: span 1;
        }

        &__center {
            grid-column: span 3;

            &__type {
                color: #000000;
            }

            &__date,
            &__user {
                color: #9a9a9a;
                font-size: 10px;
            }
        }
        &__sum {
            color: #9a9a9a;
            font-size: 12px;
            grid-column: span 1;
        }
    }
}
</style>
