<template>
  <div>
    <section
      id="balance"
      style="padding: 0px 20px; background-color: #56b897"
      class="relative"
    >
      <div class="balance_text">
        <div class="balance_text__amount">
          &pound;{{ formatNumber(guardianBalance) }}
          <!-- &pound;{{ formatPrice(guardianBalance) }} -->
        </div>

        <div class="balance_text__title">
          Available
          <div class="flex">
            <div class="flex-auto py-2 px-1">
              <button
                @click="openModulrSheet"
                class="h-[54px] border-0 px-2 rounded-full bg-white flex items-center justify-center"
                style="background: #36d5f2;"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#01A4C0"
                  class="w-[23px] h-[23px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="5"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <p
                  style="color: #01A4C0; font-size: 0.9em; "
                  class="px-1 font-bold fs-1"
                >
                  Top Up
                </p>
              </button>
            </div>
            <div class="flex-auto p-2">
              <button
                v-if="showTransactionsButton"
                @click="openTransactionsSheet"
                class="h-[54px] border-0 px-2 rounded-full bg-white flex items-center justify-center button"
                style="background: #36d5f2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#01A4C0"
                    d="M3 22V2l1.5 1.5L6 2l1.5 1.5L9 2l1.5 1.5L12 2l1.5 1.5L15 2l1.5 1.5L18 2l1.5 1.5L21 2v20l-1.5-1.5L18 22l-1.5-1.5L15 22l-1.5-1.5L12 22l-1.5-1.5L9 22l-1.5-1.5L6 22l-1.5-1.5L3 22Zm3-5h12v-2H6v2Zm0-4h12v-2H6v2Zm0-4h12V7H6v2Z"
                  />
                </svg>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="5"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
                <p
                  style="color: #01A4C0; font-size: 0.9em;"
                  class="px-2 font-bold fs-1"
                >
                  Transactions
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div v-if="guardianBalance == 0" class="free-money w-[90%] mt-[-49px] mb-[30px] mx-auto px-2 py-2 min-h-[50px] font-medium rounded text-[10px] text-center flex justify-center items-end z-[-1] bg-[#01A4C0] text-white">
            First time top-up bonus - get &pound;10 free in pocket money!
        </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["showTransactionsButton"],
  computed: {
    ...mapGetters(["guardianBalance", "modulrStatusGetter"]),
    addFundsStyle() {
      // if (
      //     this.modulrStatusGetter == 3 ||
      //     this.modulrStatusGetter == 2 ||
      //     this.modulrStatusGetter == 6
      // ) {
      //     return `linear-gradient(180deg, #36d5f2 0%, #00ccff 100%)`;
      // } else if (this.modulrStatusGetter == 4) {
      //     return `#EC6C6B`;
      // } else {
      //     return `#B9B9B9`;
      // }

      return `linear-gradient(180deg, #36d5f2 0%, #00ccff 100%)`;
    },
    strokeColor() {
      if (
        this.modulrStatusGetter == 3 ||
        this.modulrStatusGetter == 2 ||
        this.modulrStatusGetter == 6
      ) {
        return `#36d5f2`;
      } else if (this.modulrStatusGetter == 4) {
        return `#EC6C6B`;
      } else {
        return `#B9B9B9`;
      }
    },
  },
  methods: {
    openModulrSheet() {
      this.$emit("openModulrSheet");
    },
    openTransactionsSheet() {
      this.$emit("openSheetTransactions");
    },
    formatNumber(number) {
      const fixedNumber = number.toFixed(2);

      let formattedNumber = fixedNumber.toString();

      const parts = formattedNumber.split('.');

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      formattedNumber = parts.join('.');

      return formattedNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
#balance {
  background: #01A4C0;
  background: #01A4C0;
  background: linear-gradient(180deg, #01A4C0 0%, #006779 100%);
  width: 100%;
  height: 189px;
  border-radius: 28px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  * {
    transition: all 200ms ease-in-out;
  }

  .balance_text {
    color: white;

    &__title {
      font-size: 16px;
      font-weight: 700;
    }

    &__amount {
      font-size: 48px;
      font-weight: bold;
      font-weight: 700;
      letter-spacing: 0.02rem;
    }
  }

  .balance_button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      opacity: 0.8;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
